<template>
  <div
    class="vh100 d-flex flex-column justify-content-center align-items-center"
  >
    <img src="~@/assets/img/default/payment-error.svg" class="mb-7" />
    <h1>Access denied</h1>
    {{ type }}
  </div>
</template>

<script>
import { WITHOUT_ERROR_TYPE } from '@/helpers/const/errors'

export default {
  computed: {
    type() {
      return this.$route?.params?.type ?? WITHOUT_ERROR_TYPE
    }
  }
}
</script>
